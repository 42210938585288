<template>
    <div>
        
        <div v-if="loading == true" class="notification is-warning">
            Loading ...
        </div>

        <div v-if="loading==false" class="content">
            

            <h4 class="title is-size-4">Application Progress</h4>

            <div v-if="showEmailForm == false">
                <table class="table is-fullwidth  is-hoverable">
                    <thead>
                        <tr>
                            <th>Stage</th>
                            <th>Status</th>
                            <th>Completed</th>
                            <th>Approved</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="row in application.stages" :key="row.id">
                            <td>{{ row.name }}</td>
                            <td><button class="button is-small is-success" v-if="row.application_stage_active == 1">Active</button></td>
                            <td><button class="button is-small is-success" v-if="row.application_stage_started == 1">Started</button></td>
                            <td><button class="button is-small is-success" v-if="row.application_stage_approved == 1">Approved</button></td>
                            <td>
                                <select class="select is-small" required v-model="stageAction">
                                    <option value="">Select Action</option>
                                    <option value="activate">Activate</option>
                                    <option value="deactivate">De-Activate</option>
                                    <option value="approve">Approve</option>
                                    <option value="unapprove">Un-Approve</option>
                                </select>
                                <button class="button is-small is-success ml-2" @click="updateStage(row)" :disabled="working==true">Go</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            
                 <div class="buttons">
                
                        <div class="button is-fullwidth is-success mt-4" :disabled="working==true" @click="showEmailForm= true" >Compose Email To Send Back To Applicant</div>
                        <div class="button is-fullwidth is-link mt-4" :disabled="working==true" @click="reOpenApplication(3)" v-if="application.status==2" >Application Is Locked For The Member - Click Here To Re-Open It Without Sending Email</div>
                        <div class="button is-fullwidth is-link mt-4" :disabled="working==true" @click="reOpenApplication(0)" v-if="application.status==1" >Application Is Locked For The Member - Click Here To Re-Open It Without Sending Email</div>
                 </div>
                 
            <br />
            <p>Send this link to the applicant so they can edit their application</p>
            <p ><a :href="'https://applications.balmoral-healthworks.co.uk/view/' + application.code" target="_blank">https://applications.balmoral-healthworks.co.uk/view/{{ application.code }}</a> - <span style="cursor:pointer" @click="copyToClipboard('https://applications.balmoral-healthworks.co.uk/view/' + application.code )">Copy to Clipboard</span></p>
            <br />

            </div>

            <div v-if="showEmailForm==true">
                <div class="field">
                    <label class="label">Compose Your Email</label>
                    <div class="control">
                        <textarea class="textarea" v-model="application.email_text" rows="12"></textarea>
                    </div>
                </div>

                <div class="field is-grouped">
                <div class="control">
                    <button class="button is-success" @click="returnApplication()" :disabled="working==true">Send Email</button>
                </div>
                <div class="control">
                    <button class="button is-danger is-light" @click="showEmailForm=false" :disabled="working==true">Cancel</button>
                </div>
                </div>

                
            </div>
           
            <div v-if="application.cleared == 0">
                <h4 class="title is-size-4">Clearing the Application</h4>
                <p>Press the button below which will complete the following tasks :</p>
                <ul class="">
                    <li>Change the member status from application to member</li>
                    <li>Create an account in Auth0 to allow the member to login to the app</li>
                    <li>Email the password to yourself which can then be sent on to the new member</li>
                    <li>Store the date that the applicant was approved and enable the app in the database</li>
                    <li>Mark the application as complete.</li>
                </ul>
                <div class="button is-fullwidth is-danger mt-4" :disabled="working==true" @click="clearApplication()" >Clear the applicant now</div>
            </div>
            <div v-if="application.cleared == 1">
                <div class="button is-fullwidth is-success mt-4" >Applicant has already been cleared</div>
            </div>
           
        </div>
    </div>
</template>

<script>
//import MemberMixin from '@/mixins/MemberMixin.js';

import MemberService from '@/services/MemberService.js';
import UtilityService from '@/services/UtilityService.js';

export default {
    name: 'MemberApplication',
    data: function () {
            return {
                working: false,
                   application: [{email_text: ''}],
                   loading: true,
                   tabToShow : 'personal',
                   stageAction: '',
                   showEmailForm: false,
                   
                   
            }
        },
        props: ['member_id', 'member_application_id'],
        methods: {
            async clearApplication () {
                if(confirm("Are you sure you want to clear this applicant ?"))
                {
                    this.working = true

                    
                    var formdata = { 
                    member_application_id : this.member_application_id
                    }; 

                    MemberService.clearApplication(this.accessToken, formdata, this.member_application_id)
                    .then(
                        () => {
                            this.working=false;
                            
                            alert("Application has been cleared.")
                            this.getApplication();

                        }
                    )
                    .catch((error) => { 
                    // console.log(error)
                        if (error.response.status == 409) {
                            alert(error.response.data.message)
                        } 
                        else{
                            alert(error.message)
                        } 
                        this.working=false;
                    })
                }
                else {
                    return false
                }
            },
            copyToClipboard(textToCopy) {
                navigator.clipboard.writeText(textToCopy);
                alert("Copied - just paste this into an email now")
            },
            async getApplication() {
                this.loading=true
                UtilityService.getSimpleApiData(this.accessToken, "member_applications/" + this.member_application_id)
                .then(
                    (application => {
                        this.$set(this, "application", application);
                        this.loading=false
                    }).bind(this)
                );
            },
            async returnApplication() {
                this.working = true

                var formdata = { 
                    _method: 'patch',
                    status : 3,
                    email_text: this.application.email_text
                }; 

                MemberService.updateMemberApplication(this.accessToken, formdata, this.member_application_id)
                .then(
                    () => {
                        this.working=false;
                        this.showEmailForm = false
                        alert("Email has been sent to the applicant.")
                        this.getApplication();

                    }
                );
            },
            async reOpenApplication(new_status) {
                this.working = true

                var formdata = { 
                    _method: 'patch',
                    status : new_status,
                    email_text: "none"
                }; 

                MemberService.updateMemberApplication(this.accessToken, formdata, this.member_application_id)
                .then(
                    () => {
                        this.working=false;
                        this.showEmailForm = false
                        alert("Application has been re-opened")
                        this.getApplication();

                    }
                );
            },
            async updateStage(stage)
            {
                console.log(stage)
                if(this.stageAction == "activate")
                {
                    this.activateStage(stage)
                }
                else if(this.stageAction == "deactivate")
                {
                    this.deActivateStage(stage)
                }
                else if(this.stageAction == "approve")
                {
                    this.approveStage(stage)
                }
                else if(this.stageAction == "unapprove")
                {
                    this.unApproveStage(stage)
                }
                else if(this.stageAction == "")
                {
                    alert("Please choose an action to perform");
                }
                else {
                    alert("The chosen action could not be performed");
                }
            },
            async activateStage(this_stage) {
                this.working = true

                var formdata = { 
                    _method: 'patch',
                    active : 1,
                }; 

                if(this_stage.application_stage_id == null || this_stage.application_stage_id == 0)
                {
                    formdata = { 
                        member_application_id : this.application.id,
                        stage_id: this_stage.id,
                        active : 1,
                    }; 

                    // Create a new stage for this application
                    MemberService.createApplicationStage(this.accessToken, formdata)
                    .then(
                        () => {
                            this.working=false;
                            this.getApplication();
                        }
                    );
                }
                else{
                    MemberService.updateApplicationStage(this.accessToken, formdata, this_stage.application_stage_id)
                    .then(
                        () => {
                            this.working=false;
                            
                            this.getApplication();

                        }
                    );
                }
            },
            async deActivateStage(this_stage) {
                this.working = true

                var formdata = { 
                    _method: 'patch',
                    active : 0,
                    approved: 0
                }; 

                if(this_stage.application_stage_id == null || this_stage.application_stage_id == 0 || this_stage.application_stage_active == 0)
                {
                   alert("An active stage was not chosen. You cannot de-activate it")
                }
                else{
                    MemberService.updateApplicationStage(this.accessToken, formdata, this_stage.application_stage_id)
                    .then(
                        () => {
                            this.working=false;
                            
                            this.getApplication();

                        }
                    );
                }
            },
            async approveStage(this_stage) {
                this.working = true

                var formdata = { 
                    _method: 'patch',
                    approved : 1,
                }; 

                if(this_stage.application_stage_id == null || this_stage.application_stage_id == 0 || this_stage.application_stage_active == 0)
                {
                   alert("You must activate a stage before you can approve it.")
                }
                else{
                    MemberService.updateApplicationStage(this.accessToken, formdata, this_stage.application_stage_id)
                    .then(
                        () => {
                            this.working=false;
                            
                            this.getApplication();

                        }
                    );
                }
            },
            async unApproveStage(this_stage) {
                this.working = true

                var formdata = { 
                    _method: 'patch',
                    approved : 0,
                }; 

                if(this_stage.application_stage_id == null || this_stage.application_stage_id == 0 || this_stage.application_stage_approved == 0)
                {
                   alert("You must approve a stage before you can un-approve it.")
                }
                else{
                    MemberService.updateApplicationStage(this.accessToken, formdata, this_stage.application_stage_id)
                    .then(
                        () => {
                            this.working=false;
                            
                            this.getApplication();

                        }
                    );
                }
            },
           
        },
        created() {
            this.getAccessToken()
            .then(() => { 
                this.getApplication();
            });
        
        },
        computed : {
           
        },
}
</script>